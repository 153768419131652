.mobile_con {
  display: none;
}

.contact_web {
  display: block;
}

@media (max-width: 768px) {
  .mobile_con {
    display: block;
  }
  .contact_web {
    display: none;
  }
}
