.contact_container {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.contact_right {
  padding: 120px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.6;
}

.contact_nav {
  display: none;
}

.contrivers_heading {
  position: absolute;
  top: 20px;
  z-index: 100;
  left: 20px;
  color: white;
  font-size: 32px;
}

.lahore_marker {
  position: absolute;
  top: 45%;
  z-index: 100;
  left: 6.5%;
  color: white;
  font-size: 32px;
}

.dubai_marker {
  position: absolute;
  top: 45%;
  z-index: 100;
  left: 43.5%;
  color: white;
  font-size: 32px;
}

@media (max-width: 450px) {
  .contact_container {
    flex-direction: column;
  }

  .contact_nav {
    display: block;
  }

  .contact_right {
    padding: 20px;
  }
}

@media (max-width: 900px) {
  .contact_left {
    display: none;
  }
  .contact_nav {
    display: block;
  }
  .contact_right {
    padding: 20px;
    height: auto;
    min-height: auto;
    justify-content: normal;
    align-items: normal;
  }

  .contact_container {
    height: auto;
    display: block;
    min-height: auto;
  }
}
