.slider {
  position: relative;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px 30px;
}

.slider h2 {
  font-size: 36px;
}

.testimonial {
  text-align: center;
  padding: 20px;
}

.testimonial_quote {
  font-style: italic;
  font-size: 1.2em;
  margin-bottom: 25px;
}

/* REACT SLICK ARROW CUSTOM CSS */
.react__slick__slider__parent {
  position: relative;
}

.react__slick__slider__parent .slick-prev,
.react__slick__slider__parent .slick-next {
  position: absolute;
  top: 50%;
}
.react__slick__slider__parent .slick-prev {
  color: black;
  z-index: 1;
}
.react__slick__slider__parent .slick-next {
  border-radius: 50%;
  color: black;
  z-index: 1;
}

.react__slick__slider__parent .slick-prev:before,
.slick-next:before {
  color: black;
}

.slick-prev::before,
.slick-next::before {
  font-family: "";
}

.slick-next::before {
  content: "→";
  font-size: 30px;
}

.slick-prev::before {
  content: "←";
  font-size: 30px;
}
@media (max-width: 768px) {
  .slider {
    padding: 20px 50px;
  }

  .slider h2 {
    font-size: 22px;
  }

  .testimonial {
    font-size: 14px;
  }

  .slick-next::before {
    content: "→";
    font-size: 20px;
  }

  .slick-prev::before {
    content: "←";
    font-size: 20px;
  }
}
