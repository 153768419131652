.product-wrapper {
  margin-top: 30px;
  padding: 20px;
}

body {
  font-family: "Averta";
  color: black;
}

.product-header {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: black;
  margin-bottom: 40px;
}

.product_heading {
  font-size: 32px;
  font-weight: 800;
  margin: 20px 0;
}

.product_container {
  display: flex;
  flex-wrap: wrap-reverse;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px auto;
}

.product_container_2 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 20px auto;
}

.content,
.images {
  padding: 20px;
}

.content {
  flex: 1;
  min-width: 300px;
}

.images {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 60px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #555 !important;
  margin-bottom: 20px;
}

.product_para {
  font-size: 20px;
  color: #555 !important;
  margin-bottom: 10px;
}

.project-image-1-container {
  width: 300px;
  height: 600px;
  margin: 10px -120px;

  /* object-fit: cover; */
}
.project-image-2-container {
  width: 300px;
  height: 600px;
  margin: 10px 0;
  margin-bottom: 60px;

  /* object-fit: cover; */
}

.project-image-2 {
  border-radius: 40px;
  border: 8px solid black;
}

.project-image-1 {
  border-radius: 40px;
  border: 8px solid black;
}

@media (max-width: 768px) {
  .product_container {
    gap: 20px;
  }
  .container {
    flex-direction: column;
    align-items: center;
  }

  .content,
  .images {
    padding: 10px;
  }

  .product_heading {
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0;
  }

  .product-header {
    font-size: 24px;
  }

  .project-image-1-container {
    width: 200px;
    height: 400px;
    margin: 10px -120px;
  }
  .project-image-2-container {
    width: 200px;
    height: 400px;
    margin: 10px -130px;
    margin-bottom: 60px;
  }

  .product_para {
    font-size: 16px;
    color: #555 !important;
    margin-bottom: 10px;
  }
  .content img {
    align-items: center;
    justify-content: center;
    align-self: center;
    display: flex;
    /* width: 100%; */
  }

  .logo {
    height: 40px;
    margin-bottom: 20px;
  }
}
